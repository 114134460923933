import { authGuard, permissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'gdpr-cookie-consent',
    loadChildren: () =>
      import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
        m => m.GdprCookieConsentModule
      ),
  },
  { path: 'domains', loadChildren: () => import('./domains/domain/domain.module').then(m => m.DomainModule) },
  { path: 'domain-users', loadChildren: () => import('./domain-users/domain-user/domain-user.module').then(m => m.DomainUserModule) },
  { path: 'pstfiles', loadChildren: () => import('./pstfiles/pstfile/pstfile.module').then(m => m.PSTFileModule) },
  { path: 'agents', loadChildren: () => import('./agents/agent/agent.module').then(m => m.AgentModule) },
  { path: 'modules', loadChildren: () => import('./agents/module/module.module').then(m => m.ModuleModule) },
  { path: 'active-agent-updates', loadChildren: () => import('./active-agent-updates/active-agent-updates/active-agent-updates.module').then(m => m.ActiveAgentUpdatesModule) },
  { path: 'mappings', loadChildren: () => import('./mappings/mapping/mapping.module').then(m => m.MappingModule) },
  { path: 'migration-processes', loadChildren: () => import('./migration-processes/migration-process/migration-process.module').then(m => m.MigrationProcessModule) },
  { path: 'aadtenants', loadChildren: () => import('./aadtenants/aadtenant/aadtenant.module').then(m => m.AADTenantModule) },
  { path: 'retention-periods', loadChildren: () => import('./retention-management/retention-periods/retention-periods.module').then(m => m.RetentionPeriodsModule) },
  { path: 'retention-packages', loadChildren: () => import('./retention-management/retention-packages/retention-packages.module').then(m => m.RetentionPackagesModule) },
  { path: 'retention-policies', loadChildren: () => import('./retention-management/retention-policies/retention-policies.module').then(m => m.RetentionPoliciesModule) },
  { path: 'central-unc-shares', loadChildren: () => import('./central-unc-shares/central-unc-share/central-unc-share.module').then(m => m.CentralUncShareModule) },
  { path: 'network-definition', loadChildren: () => import('./network-definitions/network-definition/network-definition.module').then(m => m.NetworkDefinitionModule) },
  { path: 'email-templates', loadChildren: () => import('./email-templates/email-templates/email-templates.module').then(m => m.EmailTemplatesModule) },
  { path: 'agent-api-keys', loadChildren: () => import('./agent-api-keys/agent-api-key/agent-api-key.module').then(m => m.AgentApiKeyModule) },
  { path: 'mapping-progress', loadChildren: () => import('./mapping-progress/mapping-progress/mapping-progress.module').then(m => m.MappingProgressModule) },
  { path: 'agent-update-tier', loadChildren: () => import('./agent-update-tier/agent-update-tier/agent-update-tier.module').then(m => m.AgentUpdateTierModule) },
  { path: 'pst-progress-overview', loadChildren: () => import('./pst-progress-overview/pst-progress-overview.module').then(m => m.PstProgressOverviewModule)},
  { path: 'migration-progress-overview', loadChildren: () => import('./migration-progress-overview/migration-progress-overview.module').then(m => m.MigrationProgressOverviewModule)},
  { path: 'pst-file-owner-management', loadChildren: () => import('./pst-file-owner-management/pst-file-owner-management.module').then(m => m.PstFileOwnerManagementModule)},
  { path: 'pst-file-excludes', loadChildren: () => import('./pst-file-excludes/pst-file-excludes.module').then(m => m.PstFileExcludesModule)},
  { path: 'finished-mappings', loadChildren: () => import('./mapping-progress/finished-mappings/finished-mappings.module').then(m => m.FinishedMappingsModule)},
  { path: 'pst-file-item-errors', loadChildren: () => import('./pst-item-errors/pst-item-errors.module').then(m => m.PSTFileItemErrorsModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
