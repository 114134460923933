import type { AgentApiKeyType } from './enums/agent-api-key-type.enum';
import type { AgentType } from './enums/agent-type.enum';
import type { EntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';

export interface AgentApiKeyDto {
  agentApiKeyId: number;
  id: number;
  tenantId?: string;
  agentId?: number;
  apiKey?: string;
  createdOnUtc?: string;
  agentApiKeyTypeId: AgentApiKeyType;
  isEnabled?: boolean;
}

export interface AgentAvailableVersionCreateDto {
  version?: string;
  agentType: AgentType;
  branch?: string;
}

export interface AgentAvailableVersionEnableDisableDto {
  agentType: AgentType;
  version?: string;
  branch?: string;
  enabled: boolean;
}

export interface AgentCountDto {
  agentUpdateTierId: number;
  name?: string;
  priority: number;
  counter?: number;
  isUpdateEnabled?: boolean;
}

export interface AgentCredentialsMetadataDto extends EntityDto<string> {
  agentCredentialMetadataId?: string;
  tenantId?: string;
  name?: string;
  credentialType: number;
}

export interface AgentDto extends EntityDto<number> {
  agentId: number;
  agentRegistrationId?: string;
  computerId: number;
  userId?: number;
  agentType: AgentType;
  firstName?: string;
  lastName?: string;
  computerName?: string;
  userPrincipalName?: string;
  isEnabledForDiscovery: boolean;
  isEnabledForCommandRetrieval: boolean;
  isOnline: boolean;
  lastSeenDateUtc?: string;
  version?: string;
  isLatestVersion?: boolean;
  agentUpdateTierName?: string;
  tags?: string;
  localLogLevel?: string;
  remoteLogLevel?: string;
  remoteLogLevelExpiryTimeUtc?: string;
  localLogLevelExpiryTimeUtc?: string;
}

export interface AgentInsertDto {
  priority: number;
  name?: string;
  tenantId?: string;
  isUpdateEnabled: boolean;
}

export interface AgentUpdateActiveUpdateDisplayDto {
  agentUpdateActiveUpdateId: number;
  firstName?: string;
  lastName?: string;
  computerName?: string;
  agentType?: string;
  timeStamp?: string;
}

export interface AgentUpdateDto extends EntityDto<number> {
  agentId?: number;
  agentRegistrationId?: string;
  computerId?: number;
  userId?: number;
  agentTypeId?: number;
  agentUpdateTierId?: number;
  version?: string;
  major?: number;
  minor?: number;
  build?: number;
  revision?: number;
}

export interface AgentUpdateTierDto extends EntityDto<number> {
  tenantId?: string;
  name?: string;
  priority: number;
}

export interface AgentsUiDto {
  agentId: number;
  firstName?: string;
  lastName?: string;
  computerName?: string;
}

export interface GetActiveAgentUpdateInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  columnHeaders?: string;
  firstName?: string;
  lastName?: string;
  computerName?: string;
  agentType?: AgentType;
  minTimeStamp?: string;
  maxTimeStamp?: string;
  selectedRowIds?: string;
}

export interface GetAgentApiKeyInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  columnHeaders?: string;
  agentApiKeyTypeId?: AgentApiKeyType;
  isEnabled?: boolean;
  apiKey?: string;
  minCreatedOnUtc?: string;
  maxCreatedOnUtc?: string;
  selectedRowIds?: string;
}

export interface GetAgentTierInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  columnHeaders?: string;
  name?: string;
  minPriority?: number;
  maxPriority?: number;
  minNoofAgentsAssigned?: number;
  maxNoofAgentsAssigned?: number;
  selectedRowIds?: string;
  isUpdateEnabled?: boolean;
}

export interface GetAgentsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  agentIdMin?: number;
  agentIdMax?: number;
  agentRegistrationId?: string;
  agentType?: AgentType;
  firstName?: string;
  lastName?: string;
  computerName?: string;
  isEnabledForDiscovery?: boolean;
  isEnabledForCommandRetrieval?: boolean;
  isOnline?: boolean;
  minLastSeenUtc?: string;
  maxLastSeenUtc?: string;
  version?: string;
  agentUpdateTierName?: string;
  columnHeaders?: string;
  tags: string[];
  selectedRowIds?: string;
  localLogLevel?: string;
  remoteLogLevel?: string;
  userPrincipalName?: string;
  userId?: number;
  minRemoteLogLevelExpiryTimeUtc?: string;
  maxRemoteLogLevelExpiryTimeUtc?: string;
  minLocalLogLevelExpiryTimeUtc?: string;
  maxLocalLogLevelExpiryTimeUtc?: string;
}
