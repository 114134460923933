import { Environment } from '@abp/ng.core';

const baseUrl = "https://{0}.pstcomplete.develop.ci.eu.cloudficient.cloud";

const oAuthConfig = {
  issuer: 'https://{0}.identity.pstcomplete.develop.ci.eu.cloudficient.cloud/',
  skipIssuerCheck: true,
  redirectUri: baseUrl,
  clientId: 'PSTComplete_App',
  responseType: 'code',
  scope: 'offline_access PSTComplete',
};

export const environment = {
  production: false,
  application: {
    baseUrl: "https://{0}.pstcomplete.develop.ci.eu.cloudficient.cloud",
    name: 'PSTComplete',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://appapi.pstcomplete.develop.ci.eu.cloudficient.cloud',
      rootNamespace: 'cloudficient.PSTComplete',
    },
    AbpAccountPublic: {
      url: 'https://appapi.pstcomplete.develop.ci.eu.cloudficient.cloud',
      rootNamespace: 'AbpAccountPublic',
    }
  },
  // remoteEnv: {
  //   url: '/getEnvConfig',
  //   mergeStrategy: 'deepmerge'
  // },
  appInsights: {
    instrumentationKey: '291b67c7-e47e-455d-805f-8e9c3871aa61'
  }
} as Environment;
 